export const text = {
  // myGroenhart: 'Mijn Groenhart',
  // extra: 'Extra',
  // help: 'Hulp',
  // email: 'E-mail',
  // emailAddress: 'E-mailadres',
  // password: 'Wachtwoord',
  // forgotPassword: 'Wachtwoord vergeten',
  // login: 'Inloggen',
  // loginShort: 'Log in',
  // createAccount: 'Maak een account aan',
  // noAccountYet: 'Heb je nog geen account?',
  // whyLogin: 'Waarom inloggen?',
  // loginForPriceDeals: 'Log in voor je prijsafspraken',
  // profile: 'Profiel',
  // closestLocationCardTitle: 'Dichtstbijzijnde vestiging',
  // category: 'Categorie',
  // categories: 'Categorieën',
  // changePassword: 'Wijzig wachtwoord',
  // confirmPassword: 'Bevestig wachtwoord',
  // brochuresTitle: 'Uitgelicht',
  // productsTitleLoggedIn: 'Opnieuw bestellen',
  // productsTitleLoggedOut: 'Mogelijk interessant',
  // allDeals: 'Alle acties',
  // brochure: 'Brochure',
  // assortment: 'Assortiment',
  // moreInfo: 'Meer info',
  // continue: 'Ga verder',
  // continueAsGuest: 'Ga verder als gast',
  // back: 'Terug',
  // backToHome: 'Terug naar het startscherm',
  // newsMessage: 'Nieuwsbericht',
  // newsMessages: 'Nieuwsberichten',
  // nextNewsmessage: 'Volgende nieuwsbericht',
  // foundProducts: 'Gevonden producten',
  // filter: 'Filter',
  // filters: 'Filters',
  // delete: 'Wissen',
  // toFilter: 'Filteren',
  // send: 'Verzenden',
  // showProducts: 'Toon producten',
  // showMore: 'Toon meer',
  // showLess: 'Toon minder',
  // sortBy: 'Sorteren op',
  // subNewsletter: 'Abonneer op de nieuwsbrief',
  // saleInfo: 'Actie info',
  // buttonSendWhatsapp: 'Stuur snel een appje',
  // buttonCallLocation: 'Bel de vestiging',
  // buttonSendEmail: 'Stuur een e-mail',
  // openingHours: 'Openingstijden',
  // comeAndGo: 'Afhaalservice voor webshop orders',
  // searchEmptyPlaceholder: 'Zoek bijvoorbeeld op merk, product, artikelcode, EAN of trefwoord.',
  // searchBarPlaceholder: 'Waar ben je naar op zoek?',
  // searchBarPlaceholderLocations: 'Zoek op plaatsnaam of postcode',
  // searchMoreCharacters: 'Voer meer dan 2 karakters in',
  // noLocationsFound: 'We hebben geen vestigingen in',
  // recentlySearched: 'Recent gezocht',
  // showAllProducts: 'Laat alle producten zien',
  // message404: 'We hebben je de verkeerde kant op gestuurd of moeten deze pagina nog bouwen.',
  // message500: 'Er is iets misgegaan, probeer het later opnieuw.',
  // title404: 'Oeps, hier slaan we de plank even mis!',
  // title500: 'Oeps, er is iets misgegaan!',
  // productPictures: "Product photo's",
  // emptyCartHeading: 'Doe-het-zelf: vul je winkelwagen.',
  // emptyCart: 'Je winkelwagen is nog leeg',
  // emptyCartAssortimentButton: 'Bekijk ons assortiment',
  // emptyCartSalesButton: 'Bekijk onze acties',
  // titleCart: 'Winkelwagen',
  // inclVat: 'Incl. btw',
  // exclVat: 'excl. btw',
  // add: 'Plus',
  // minus: 'Min',
  // checkDetails: 'Bekijk details',
  // addToBasket: 'Voeg toe aan winkelwagen',
  // addToBasketSticky: 'Voeg toe',
  // addToOrderList: 'Voeg toe aan bestellijst',
  // remove: 'Verwijderen',
  // edit: 'Wijzigen',
  // resetPassword: 'Reset je wachtwoord',
  // subTotal: 'Subtotaal',
  // discountCode: 'Kortingscode',
  // VAT: 'btw',
  // VATNumber: 'BTW nummer',
  // shipping: 'Bezorging',
  // totalPrice: 'Totaalprijs',
  // totalAmount: 'Totaalbedrag',
  // free: 'Gratis',
  // continueOrder: 'Verder met bestellen',
  // continueShopping: 'Verder winkelen',
  // addToOrderListOverlay: 'Producten vanuit bestellijst toevoegen',
  // copyToOrderList: 'Kopieer alle producten naar bestellijst',
  // deleteCart: 'Winkelwagen legen',
  // annuleren: 'Annuleren',
  // allProductsDeleted: 'Alle artikelen worden uit jouw winkelwagen verwijderd.',
  // voucherOrDiscount: 'Voucher of kortingscode',
  // enterDiscount: 'Voer jouw code in',
  // applyDiscount: 'Pas toe',
  // delivery: 'Levering',
  // amount: 'stuks',
  // inStock: 'op voorraad',
  // inStockIn: 'op voorraad in',
  // articleStock: 'Artikelvoorraad',
  // notInStock: 'Niet op voorraad',
  // next: 'Volgende',
  // save: 'Opslaan',
  // date: 'Datum',
  // quantity: 'Aantal',
  // groenhartLocations: 'Onze vestigingen',
  // closed: 'Gesloten',
  // confirm: 'Bevestigen',
  // relatedProducts: 'Gerelateerde producten',
  alert: {
    // see: 'Bekijk',
    // productAddedBasket: 'Product toegevoegd aan winkelwagen',
    // productAddedOrderList: 'Product toegevoegd aan bestellijst',
    // productRemovedBasket: 'Product verwijderd uit winkelwagen',
    // productRemovedOrderList: 'Product verwijderd uit bestellijst',
    // adresChanged: 'Afleveradres succesvol aangepast',
    // newAdresAdded: 'Nieuw afleveradres succesvol toegevoegd',
    // somethingWentWrong: 'Er is iets mis gegaan',
  },
  basket: {
    // deliveryNumber: 'levering',
  },
  checkout: {
    // order: 'Bestellen',
    // personalDetails: 'Persoonlijke gegevens',
    // companyName: 'Bedrijfsnaam',
    // firstName: 'Voornaam',
    // lastName: 'Achternaam',
    // deliveryAddress: 'Bezorgadres',
    // street: 'Straat',
    // city: 'Plaats',
    // kvkNumber: 'Kvk nummer',
    // infix: 'Tussenvoegsel',
    // phoneNumber: 'Telefoonnummer',
    // mobile: 'Mobiel',
    // postalCode: 'Postcode',
    // houseNumber: 'Huisnummer',
    // addition: 'Toevoeging',
    // address: 'Adres',
    // deliveryMethod: 'Bezorgmethode',
    // paymentMethod: 'Betaalmethode',
    // orderDetails: 'Bestelgegevens',
    // extraOrderDetails: 'Extra bestelinformatie',
    // purchaseNumber: 'Inkoopnummer',
    // projects: 'Projecten', => checkOutProjects
    // orderedBy: 'Besteld door',
    // remarks: 'Opmerkingen',
    // work: 'Werk',
    // remarksPlaceholder: 'Opmerkingen over bestelling',
    // items: 'items',
    // item: 'item',
    // confirmOrder: 'Bestelling bevestigen',
    // sendOffers: 'Ja, stuur mij max. eens per week aanbiedingen en informatie van Groenhart',
    // acceptTerms:
    //   'Door op ‘Bevestigen’ te klikken plaats ik een bestelling met betaalplicht en ga ik akkoord',
    // terms: 'Algemene voorwaarden',
    // status: 'Status',
    // addNewAddress: 'Nieuw adres toevoegen',
    // name: 'Naam', => checkoutName
    // invoiceAddress: 'Factuuradres', => checkoutInvoiceAddress
    validation: {
      // password:
      //   'Voer een geldig wachtwoord in van 10 karakters met minstens: 1 hoofdletter, 1 kleine letter, 1 nummer en 1 speciaal teken.', => validationPassword
      // phoneNumber: 'Voer een geldig telefoonnummer in', => validationPhoneNumber
      // email: 'Voer een geldig e-mailadres in', => validationEmail
      // postalCode: 'Voer een geldige postcode in (1234AB)', => validationPostalCode
      // houseNumber: 'Voer een geldig huisnummer in', => validationHouseNumber
      // purchaseNumber: 'Voer een geldig inkoopnummer in', => validationPurchaseNumber
    },
    // selectBank: 'Selecteer jouw bank',
    // selectCredit: 'Selecteer jouw creditcard',
    // handleOrder: ', we gaan deze klus voor je klaren!',
    // buildOnUs: 'Op ons kun je bouwen:',
    // orderConfirmationMail: 'Je krijgt een bestelbevestiging in je mail.',
    // weCheckOrder: 'Wij controleren je bestelling.',
    // messageWhenOrderReady: 'Je krijgt bericht als we zover zijn.',
    // backToHomePage: 'Terug naar het startscherm',
    // createAccount: 'Account aanmaken', => checkoutCreateAccount
    // createAccountIntro: 'Niet verplicht, wel handig',
    // advantagesOfAccount: 'De voordelen van een account',
  },
  myAccount: {
    // logout: 'Uitloggen',
    // favoriteLocation: 'Favoriete vestiging',
    // myOrders: 'Mijn bestellingen',
    // orders: 'Bestellingen',
    // orderLists: 'Bestellijsten',
    // orderHistory: 'Bestelhistorie',
    // name: 'Naam', => accountName
    // adresses: 'Adressen',
    // invoices: 'Facturen',
    // projects: 'Projecten',
    // customerService: 'Klantenservice',
    // frequentlyAskedQuestions: 'Veelgestelde vragen',
    // requestQuote: 'Offerte aanvragen',
    // last30Days: 'afgelopen 30 dagen',
    // orderNumber: 'Bestelnummer',
    // orderPrice: 'Bedrag',
    // orderStatus: 'Meer informatie over de status',
    // placeOrder: 'Doe een bestelling',
    // invoiceAddress: 'Factuuradres',
    // myDetails: 'Mijn gegevens',
    // currentPassword: 'Huidig wachtwoord',
    // newPassword: 'Nieuw wachtwoord',
    // saveDetals: 'Gegevens opslaan', => saveDetails
    // changePassword: 'Wachtwoord aanpassen',
    // myAddresses: 'Mijn adressen',
    // billingAddress: 'Factuuradres',
    // deliveryAddress: 'Afleveradres', => accountDeliveryAddress
    // saveAddress: 'Adres opslaan',
    // defaultAddressLabel: 'Dit is je standaard adres',
    // setAsDefaultAddress: 'Als standaard gebruiken',
    // changeBillingAddressInfo:
    //   'Voor het wijzigen van je factuurgegevens kun je contact opnemen via mail of chat',
    // logoutConfirmation:
    //   'Weet je het zeker? Als je uitlogt zie je geen persoonlijke prijsafspraken meer.',
    // invoiceNumber: 'Factuurnummer',
    // pay: 'Betalen',
    // helpTitle: 'Waar staat mijn klantnummer?',
    // helpText:
    //   'Betalen bij Groenhart is veilig; via iDeal >handel je direct de betaling af met je eigen bank in een vertrouwde online betaalomgeving. We zorgen voor een correcte afhandeling.',
    // openInvoices: 'Openstaande facturen',
    // paidInvoices: 'Betaalde facturen',
    // paymentSuccesful: 'Betaling gelukt',
    // openInvoiceMessage: 'Nog een openstaande factuur? Betaal dan snel en makkelijk via',
  },
  register: {
    // needHelp: 'Hulp nodig?',
    // alreadyHaveAccount: 'Heb je al een account?',
    // register: 'Registreren',
    // passwordRequirements:
    //   'Wachtwoord moet minimaal 8 karakters lang zijn, 1 hoofdletter, 1 kleine letter, 1 cijfer en 1 speciaal karakter bevatten',
    // confirmPassword: 'Wachtwoord bevestigen', => registerConfirmPassword
    // loginDetails: 'Inloggegevens',
    // country: 'Land',
    // invoicing: 'Facturatie',
    // invoicingEmail: 'Facturatie e-mailadres',
    // newsletterAndPrivary: 'Nieuwsbrief en privacy',
    // signUp: 'Meld je aan',
    // newCustomer: 'Nieuwe klant?',
    // registerCheckoutIntro:
    //   'Vul je e-mailadres in om veilig in te loggen. Rond daarna je bestelling af.',
    // existingClient: 'Bestaande klant?',
    // fillInEmailToContinue: 'Vul je e-mailadres in om verder te gaan',
    // activateAccountIntro:
    //   'Ben je reeds klant bij ons en heb je een klantnummer? Dan kun je via onderstaand formulier je webshop account activeren om ook via de webshop gebruik te maken van je bestaande condities.',
    // newToGroenhart: 'Nieuw bij Groenhart?',
    // activateAccount: 'Activeer account',
    // clientNumber: 'Klantnummer',
    // lastInvoiceNumber: 'Laatste factuurnummer',
    // noInvoiceYet: 'Ik heb nog geen factuur ontvangen',
    success: {
      // header: 'Je account is geactiveerd!',
      // toShop: 'Ga naar de winkel',
      // body: 'Je hebt een bevestigingsmail ontvangen op Fleur@bikkelhart.nl met een tijdelijk wachtwoord',
    },
    // preferredPaymentMethod: 'Gewenste betaalmethode',
  },
  scanner: {
    // scanQRorBarcode: 'Scan QR- of barcode',
    // scannerIntro: 'Als het is gelukt, komt de volgende stap automatisch in beeld. Lukt het niet?',
    // typeInArticleNumber: 'Typ het artikelnummer in',
    // permissionNeededMessage:
    //   'Ga naar de instellingen van je telefoon en geef toegang tot je camera om de scanfunctie te gebruiken.',
    // permissionTitle: 'Scan jouw product',
    // permissionIntro:
    //   'Gebruik je camera en zie direct welke condities jij met ons hebt afgesproken. Binnen een paar klikken zit het product in je winkelwagen.',
    // permissionButton: 'Naar de scanner',
    // thisIsHowItWorks: 'Zo werkt het',
    // help1: 'Richt de scanner op de streepjescode of de barcode van het product.',
    // help2: 'Ben je in een donkere ruimte? Gebruik dan de flitsfunctie.',
    // articleNumber: 'Artikelnummer',
  },
  alertMessages: {
    // newAddress: 'Nieuw afleveradres succesvol toegevoegd', => newAdresAdded
    // deleteAddress: 'Afleveradres succesvol verwijderd',
    // editAddress: 'Afleveradres succesvol aangepast',
    // deleteAddressConfirmation: 'Weet je het zeker dat je dit adres wilt verwijderen?',
    // addedToWishlist: 'Product toegevoegd aan bestellijst',
    // changePersonalDetailsSuccess: 'Persoonlijke gegevens succesvol aangepast',
    // changePasswordSuccess: 'Wachtwoord succesvol aangepast',
    // generalError: 'Er is iets misgegaan', => somethingWentWrong
    // successfullyImportListToCart: 'Producten succesvol toegevoegd',
  },
  wishlist: {
    // addAllToCart: 'Voeg alles toe aan winkelwagen',
    // emptyListHeader: 'Duik in ons (gereed)schap om je bestellijst te vullen.',
    // emptyListHeader2: 'Je lijst is nog leeg',
    // emptyListMessage: 'Druk op de plusknop rechts boven om je eerste bestellijst te maken',
    // emptyListButton: 'Ontdek ons assortiment',
    // editNameTitle: 'Naam aanpassen',
    // inputPlaceholder: 'Voer een naam in',
    // removeAllProducts: 'Alle artikelen(en) verwijderen',
    // removeList: 'Lijst verwijderen',
    // pageTitle: 'Bestellijst',
    // newList: 'Nieuwe lijst',
    // newWishlist: 'Nieuwe bestellijst',
    // noListHeader: 'Klik op de plus en begin je eerste klus',
    // noListHeader2: 'Maak je eerste bestellijst',
    // noListMessage: 'Druk op de plusknop rechts boven om je eerste bestellijst te maken',
    // products: 'artikel(en)',
  },
  orderHistory: {
    // searchPlaceholder: 'Zoek een product of merk',
    // noSearchResultsHeader: 'Geen artikelen gevonden',
    // noSearchResultsMessage:
    //   'Probeer het opnieuw met een ander trefwoord of gebruik een algemene term.',
    // checkBasket: 'Bekijk winkelwagen',
  },
  orders: {
    // infoTitle: 'Wat betekent de status van het product?',
    // pendingText: 'order is zojuist geplaatst en moet door ons nog worden ingeladen.',
    // processingText: 'de order is doorgezet naar ons magazijn. Zij lopen op dit moment je order.',
    // reservedText:
    //   "je order wordt in z'n volledigheid geleverd, echter staat het artikel in backorder waardoor deze order hierop wacht om geleverd te worden.",
    // backOrderText:
    //   'dit artikel is in backorder. Deze wordt nageleverd zodra hij bij ons binnen is.',
    // inTransitText:
    //   'je order is gereed en wordt op dit moment door de chauffeur gebracht naar het afleveradres wat je hebt opgegeven.',
    // deliveredText: 'je order is bij je bezorgd of je hebt hem opgehaald bij de vestiging.',
    // billedText:
    //   'je order is reeds afgehandeld door ons en de factuur hiervan is naar je toegestuurd. Kijk eventueel bij Mijn Groenhart -> facturen of deze reeds betaald is of nog betaald moet worden.',
    // specificationText:
    //   'je hebt meerdere items van dit artikel besteld eneen deel hiervan is reeds geleverd. De rest staat nog in backorder en wordt nageleverd zodra deze bij ons binnen is.',
    // orderedBy: 'Besteld door', => ordersOrderedBy
    // totalAmount: 'Totaalbedrag', => ordersTotalAmount
    // deliveryMethod: 'Bezorgmethode',
    // orderDate: 'Besteldatum',
  },
  brand: {
    // overlayTitle: 'Merk info',
  },
  search: {
    // noResults: 'Geen resultaten gevonden.',
  },
  removeAccount: {
    // confirmMessage: 'Weet je zeker dat je je account wil verwijderen?',
    // confirmButton: 'Ja',
    // removeAccountBtn: 'Account verwijderen',
  },
};
